import React from 'react';
import NotFound from '../assets/oops.jpg';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

function NoPage() {
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen flex flex-col items-center gap-3">
    <img className="w-[500px]" src={NotFound} alt="not found page" />
    <Button
      onClick={() => {
        navigate('/', { replace: true });
      }}
      className={`!px-5 !py-2 rounded !text-white !text-sm !bg-[#002e6d] !normal-case`}
    >
      Go to Home
    </Button>
  </div>
  )
}

export default NoPage;