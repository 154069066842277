import { createHashRouter, RouterProvider } from "react-router-dom";
import StandardLanding from "./pages/StandardLanding";
import NoPage from "./pages/NoPage";

const router = createHashRouter([
  {
    path: "/:pageName?",
    element: <StandardLanding />,
  },  
  {
    path: "*",
    element: <NoPage />,
  }
]);

function App() {
  return (
    <>
    <RouterProvider router={router} />
    </>
  );
}

export default App;
